body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.displayNone {
  display: none !important;
}

.errorMessage {
  color: #bf1650;
}

.errorMessage::before {
  display: inline;
  content: "⚠ ";
}

.text-flex {
  display: flex;
}

.justify-content-right {
  display: flex;
  justify-content: right;
}

.justify-content-center {
  display: flex;
  justify-content: center;
}

.justify-content-space-between {
  display: flex;
  justify-content: space-between;
}

.color-black {
  color: black !important;
}

.table th,
.jsgrid .jsgrid-table th,
.table td,
.jsgrid .jsgrid-table td {
  padding: 0.9rem 1.375rem !important;
}

.whatsapp-card {
  font-size: 12px;
}

.user-list {
  width: 300px;
  height: 80vh;
  background-color: white;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-right: 5px solid #e9e9e9;
}

.chat-messages {
  padding: 10px;
  width: 100%;
  background-color: #f9f9f9;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  min-width: 600px;
}

.chat-message-header {
  height: 45px;
  background: white;
  display: flex;
  align-items: center;
  padding-left: 8px;
  border-bottom: 1px solid #e9e9e9;
}

.user-list-header {
  height: 45px;
  background: white;
  display: flex;
  align-items: center;
  padding-left: 8px;
  border-bottom: 1px solid #e9e9e9;
  font-size: 12px;
}

.user-inbox {
  height: 35px;
  background: #f9f9f9;
  display: flex;
  align-items: center;
  padding-left: 8px;
  border-bottom: 1px solid #e9e9e9;
  font-size: 12px;
  font-weight: bold;
  border-left: 1px solid #e9e9e9;
}

.user-list-header {
  color: #6e747a;
}

.search-input {
  border: none;
}

.search-input::placeholder {
  color: #7c7979;
  opacity: 1;
}

.profile-name {
  padding-left: 5px;
}

.profile-image img {
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

.user-list-index {
  overflow-x: auto;
  height: 69vh;
}

.user-list-index::-webkit-scrollbar,
#scrollableDiv::-webkit-scrollbar {
  width: 3px;
  /* Change the width as needed */
}

.user-list-index::-webkit-scrollbar-track,
#scrollableDiv::-webkit-scrollbar-track {
  background-color: transparent;
  /* Change the track color as needed */
}

.user-list-index::-webkit-scrollbar-thumb,
#scrollableDiv::-webkit-scrollbar-thumb {
  background-color: rgb(225, 222, 222);
  /* Change the thumb color as needed */
  border-radius: 5px;
}

.user-list-index::-webkit-scrollbar-thumb:hover,
#scrollableDiv::-webkit-scrollbar-thumb:hover {
  background-color: darkgray;
  /* Change the thumb color on hover as needed */
}

.user-icon-data-list {
  cursor: pointer;
}

.user-icon-data-list li {
  border-bottom: 1px solid #e9e9e9;
}

.text-truncat-message {
  white-space: nowrap;
  width: 215px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chat-part {
  height: 58vh;
}

.messagebox {
  background-color: white;
}

.contactNotFound {
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #a39f9f;
}

.whatsappImageChat {
  border-radius: 50%;
  width: 300px;
  height: 300px;
}

/* .messagebox input{
  border: none;
} */

.color-blue {
  color: #4b49a6;
}

.whatsapp-card .card-body {
  overflow: auto;
}

.object-fit-cover {
  object-fit: cover;
}

#scrollableDiv {
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
}

.messageItem {
  display: flex;
  overflow: hidden;
}

.messageRight {
  justify-content: right;
}

.messageLeft {
  justify-content: left;
}

.messageLeftColor {
  justify-content: left;
  background-color: #4B49AC;
}

.messageRightColor {
  justify-content: right;
  background-color: #4B49AC;
}

.messageItem .message {
  margin: 5px;
  color: white;
  min-height: 30px;
  max-width: 600px;
  min-width: 30px;
  display: flex;
  padding: 7px;
  border-radius: 5px;
}

.infinite-scroll-component__outerdiv .infinite-scroll-component {
  overflow: hidden !important;
}

.whatsapp-title {
  color: #4B49AC;
}

.whatsapp-QR li {
  margin: 15px;
}

.backgroud-gray {
  background-color: #f4f0f0;
  padding: 5px;
  font-size: 10px;
  border-radius: 2px;
}

.centerdiv {
  justify-content: center;
  display: flex;
  align-items: center;
}

.qrLoader {
  margin-left: 26%;
  margin-bottom: 14px;
}

.loaderMessage {
  font-size: 13px;
}

.sendMessageButton {
  background: #4b49a6;
  color: white;
  border: 1px solid white;
  height: 100%;
  width: 45px;
  font-size: 25px;
}

.cursor-pointer {
  cursor: pointer;
}

.br-8 {
  border-radius: 8px !important;
}

/* For Loader */
.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #4b49a6;
  border-color: #4b49a6 transparent #4b49a6 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader-manage {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: center;
}

.eye-icon-copy-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  color: #4B49AC;
}

.m-copy-icon {
  margin-left: 5px;
}

#__react-alert__ div div div {
  /* width: auto !important; */
  font-size: 12px;
  width: 350px !important;
}

#__react-alert__ div {
  z-index: 1052 !important;
}

.fixed-top {
  z-index: 100 !important;
}

.section-login {
  display: flex;
  justify-content: center;
  align-items: center;
}

.select-border {
  border-color: #ced4da !important;
  margin-right: 4px;
}

.space-evenly {
  justify-content: space-evenly;
}

.createAccount {
  text-decoration: none;
  color: #4b49a6;
  font-weight: 500;
  font-size: 14px;
  text-align: right;
}

.otp-input {
  width: 50px;
  padding: 9px 17px;
  margin-right: 10px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.color-green {
  color: green;
}

.color-red {
  color: red;
}

.align-items-center {
  align-items: center;
}

.modal-dialog {
  width: 500px;
  border-radius: 0.3rem;
}

.react-responsive-modal-modal {
  padding: 0px !important;
  max-width: 15000px !important
}

.modal-dialog-lg {
  width: 1300px;
  border-radius: 0.3rem;
}

.text-center {
  text-align: center;
}

.color-blue {
  color: #4b49a6;
}

.chip {
  background-color: #4b49a6 !important;
}

.multiSelectContainer li:hover {
  background-color: #4b49a6 !important;
  color: white !important;
}

.highlightOption {
  background-color: transparent !important;
  color: black !important;
}

.multiSelectContainer ul {
  max-height: 180px !important;
}

.btn-payload {
  padding: 6px;
  font-size: 13px;
}

.weekDays .search-wrapper {
  min-height: 50px;
  display: flex;
  flex-flow: wrap;
}

.weekDays .search-wrapper ::placeholder {
  color: #cccbcb;
}

.truncat-message {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 140px;
  line-height: 1.5em
}

.acitiveMessageTab {
  background: #4b49a6;
  color: white;
}

.color-red {
  color: red;
}

/* .truncat-message {
  overflow: hidden;
  width:100px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
} */

ul.pagination li {
  border: 1px solid #4b49a6;
  padding: 5px 12px;
  border-radius: 5px;
  margin-right: 5px;
}

ul.pagination li.active {
  background: #4b49a6;
  color: white;
}

ul.pagination li.previous a,
ul.pagination li.next a {
  text-decoration: none;
  color: #4b49a6;
}

.peginationDisplay {
  margin-top: 20px;
  display: flex;
  justify-content: right;
}

.text-decoration-none {
  text-decoration: none;
}

.color-blue {
  color: #4b49a6;
}

.group-multiselect-contact .multiselect-container .search-wrapper {
  padding: 10px;
  padding-left: 31px;
  min-height: 50px;
}

.group-multiselect-contact .multiselect-container .search-wrapper input::placeholder {
  color: #cccbcb;
  font-size: 15px;
}

.customeIconEditor {
  border: 1px solid #F1F1F1;
  padding: 5px;
  min-width: 25px;
  height: 20px;
  border-radius: 2px;
  margin: 0 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: white;
  text-transform: capitalize;
  font-size: 13px;
}

.download-demo-csv {
  font-size: 12px;
  color: #4b49a0;
  cursor: pointer;
}

/* Switch */

.switch {
  position: relative;
  display: inline-block;
  width: 52px;
  height: 25px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 34px;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 17px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  border-radius: 50%;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: #4b49a6;
}

input:checked+.slider:before {
  transform: translateX(26px);
}

.profile-img{
  width: 200px;
  height: 200px;
  margin-bottom: 20px;
  border-radius: 100px;
  cursor: pointer;
}

.font-textbox {
  font-size: 16px !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.upload-text {
  font-size: 1.2rem !important;
  font-weight: 500;
  margin-top: -1rem;
  text-decoration: underline;
  cursor: pointer;
}

.edit-icon-profile  {
  position: absolute;
  right: 0;
}